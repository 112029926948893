import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ProxyService } from './proxy.service';
import { map } from 'rxjs/operators';



@Injectable()
export class UserService {
  /** HTTPリクエストProxy設定 */
  protected proxySetting: any;

  constructor(private _http: HttpClient, protected proxyService: ProxyService) {
    this.proxySetting = this.proxyService.getProxySetting();
  }

  /**
   * ユーザー名をサーバから取得
   */
  getUsers(): Observable<any> {
    return this._http.get(this.proxySetting.server + 'getUser').pipe(map(res => res['response']));
  }

  /**
   * 現在のユーザーをログアウトする
   */
  logoutUser() {
    sessionStorage.clear();
    return this._http.get(this.proxySetting.server + 'logout');
  }
}
