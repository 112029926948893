import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {
  NbActionsModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbContextMenuModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSidebarModule,
  NbTabsetModule,
  NbThemeModule,
  NbUserModule,
  NbCheckboxModule,
  NbAccordionModule,
  NbBadgeModule,
  NbButtonModule,
  NbSelectModule,
} from '@nebular/theme';

import {
  DatepickerComponent,
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  ThemeSwitcherComponent,
} from './components';
import { CapitalizePipe, PluralPipe, RoundPipe, TimingPipe, SafeHtmlPipe } from './pipes';
import {
  AppLayoutComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { ProxyService } from '../@core/data/proxy.service';
import { ConfigService } from '../@core/data/config.service';
import { UserService } from '../@core/data/users.service';
import { DatepickerService } from '../@core/data/datepicker.service';
import { ReportService } from '../@core/data/report.service';
import { ScenarioConfigService } from '../@core/data/scenario-config.service';
import { UserActivityService } from '../@core/data/user-activity.service';
import { AccountConfigService } from '../@core/data/account-config.service';
import { EngineerConfigService } from '../@core/data/engineer-config.service';
import { GroupConfigService } from '../@core/data/group-config.service';
import { ScreenCaptureFilterService } from '../@core/data/screencapture.filter.service';
import { LoadingService } from '../@core/data/loading.service';

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];

const NB_MODULES = [
  NbCardModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbContextMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NbAccordionModule,
  NbBadgeModule,
  NgbModule,
  NbButtonModule,
  NbSelectModule,
];

const COMPONENTS = [
  ThemeSwitcherComponent,
  DatepickerComponent,
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  AppLayoutComponent,
];

const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  SafeHtmlPipe,
];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot(
    {
      name: 'cosmic',
    },
    [ DEFAULT_THEME, COSMIC_THEME ],
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
];

const CUSTOM_PROVIDERS = [
  ProxyService,
  ConfigService,
  UserService,
  DatepickerService,
  ReportService,
  ScenarioConfigService,
  UserActivityService,
  AccountConfigService,
  EngineerConfigService,
  GroupConfigService,
  ScreenCaptureFilterService,
  LoadingService,
];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES],
  exports: [...BASE_MODULES, ...NB_MODULES, ...COMPONENTS, ...PIPES],
  declarations: [...COMPONENTS, ...PIPES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [...NB_THEME_PROVIDERS, ...CUSTOM_PROVIDERS],
    };
  }
}
