import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * カスタム日付指定のコンポネントを表示・非表示サービス
 */
@Injectable()
export class DatepickerService {

  /** Datepickerコンポネント表示フラグ */
  protected showDatepicker: boolean;
  /** Datepickerのステータスobserver */
  protected datepickerStatus: Subject<boolean> = new Subject();

  /**
   * 日付指定コンポネントの表示・非表示ステータスを設定する
   * @param status 表示・非表示ステータス
   */
  setShowDatepicker(status: boolean): any {
    this.showDatepicker = status;
  }

  /**
   * 日付指定コンポネントの表示・非表示ステータスを取得
   */
  getShowDatepicker(): boolean {
    return this.showDatepicker;
  }

  /**
   * 日付指定コンポネントのObserverの状態を設定
   */
  setDatepickerStatus(): any {
    this.datepickerStatus.next();
  }

  /**
   * 日付指定コンポネントのObserverを取得
   */
  getDatepickerStatus(): any {
    return this.datepickerStatus;
  }
}
