import { NbMenuItem } from '@nebular/theme';

export class WebVitalsConst {
    //FIDを削除(RS-890)
    static readonly METRICS: Array<string> = ['LPS', 'LCP', 'FCP', 'TTFB', 'SI', 'TBT', 'CLS', 'TTI', 'CTS', 'CBS', 'PRN'];

    static readonly LCP_BORDER_GOOD: number = 2500;
    static readonly LCP_BORDER_BAD: number = 4000;

    // static readonly FID_BORDER_GOOD: number = 100;//FIDを削除(RS-890)
    // static readonly FID_BORDER_BAD: number = 300;//FIDを削除(RS-890)

    static readonly CLS_BORDER_GOOD: number = 0.1;
    static readonly CLS_BORDER_BAD: number = 0.25;

    static readonly FCP_BORDER_GOOD: number = 2000;
    static readonly FCP_BORDER_BAD: number = 4000;

    static readonly TTFB_BORDER_GOOD: number = 200;
    static readonly TTFB_BORDER_BAD: number = 600;

    // SIの測定
    static readonly SI_BORDER_GOOD: number = 3400;
    static readonly SI_BORDER_BAD: number = 5800;

    static readonly TTI_BORDER_GOOD: number = 3800;
    static readonly TTI_BORDER_BAD: number = 7300;

    // TBTの測定
    static readonly TBT_BORDER_GOOD: number = 200;
    static readonly TBT_BORDER_BAD: number = 600;


    static readonly BYTE_TO_MB = 0.000001;

    /*static readonly CTS_BORDER_GOOD: number = 200;
    static readonly CTS_BORDER_BAD: number = 600;

    static readonly CBS_BORDER_GOOD: number = 200;
    static readonly CBS_BORDER_BAD: number = 600;

    static readonly PRN_BORDER_GOOD: number = 200;
    static readonly PRN_BORDER_BAD: number = 600;*/

    // LPSの測定
    static readonly LPS_BORDER_GOOD: number = 90;
    static readonly LPS_BORDER_BAD: number = 50;
}

export class MenuItem extends NbMenuItem {
    /**
     * アカウントの権限レベルによる制約
     * 設定した権限レベル以上のアカウントのみ表示する
     * 設定が無い場合は全てのアカウントで表示する
     * @type {number}
     */
    roleRestriction?: number | null;
}

export const PAGES_INFO: { [key: string]: MenuItem } = {
    '/pages/scenario-summary':
    {
        title: 'シナリオ概要',
        icon: 'nb-bar-chart',
        link: '/pages/scenario-summary',
        home: false,
    },
    '/pages/scenario-configs':
     {
        title: 'シナリオ設定',
        icon: 'nb-gear',
        link: '/pages/scenario-configs',
        home: false,
        roleRestriction: 4,
    },
    '/pages/user-activities':
    {
       title: '分析',
       icon: 'nb-compose',
       link: '/pages/user-activities',
       home: false,
       roleRestriction: 2,
   },
    '/pages/account-config':
     {
        title: 'アカウント設定',
        link: '/pages/account-config',
        home: false,
        hidden: true,
    },
    '/pages/engineer-configs':
    {
        title: 'エンジニア設定',
        link: '/pages/engineer-configs',
        home: false,
        hidden: true,
    },
    '/pages/engineer-group-config':
    {
        title: 'エンジニア担当グループ設定',
        link: '/pages/engineer-group-config',
        home: false,
        hidden: true,
    },
    '/pages/group-configs':
    {
        title: 'グループ設定',
        link: '/pages/group-configs',
        home: false,
        hidden: true,
    },
    '/pages/group-config':
    {
        title: 'グループ設定',
        link: '/pages/group-config',
        home: false,
        hidden: true,
    },
    // 'url':
    // {
    //      title: '',
    //      icon: 'nb-bar-chart',
    //      link: 'url',
    //      home: boolean,
    //      hidden: boolean,
    //      roleRestriction: number,
    // },
};
