import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProxyService } from './proxy.service';

@Injectable()
export class MailService {
  /** HTTPリクエストProxy設定 */
  protected proxySetting: any;

  constructor(private _http: HttpClient, protected proxyService: ProxyService) {
    this.proxySetting = this.proxyService.getProxySetting();
  }

  /**
   * 相談画面を開く場合はメールを送信する
   */
  sendConsultationModalMail(): any {
    return this._http.post(
      this.proxySetting.server + 'sendConsultationModalMail',
      this.proxySetting.baseRequestOptions.clone({
        headers: this.proxySetting.headers,
      }),
    );
  }

  /**
   * 相談内容を送信する
   */
  sendConsultationMail(data: FormData): any {
    return this._http.post(
      this.proxySetting.server + 'sendConsultationMail',
      data,
      this.proxySetting.baseRequestOptions.clone({
        headers: this.proxySetting.headers,
      }),
    );
  }
  
  /**
   *  シナリオメッセージの登録する、メール送信する
   */
  sendScenarioMail(scenarioDatainfo: any): any {
    const scenarioData = [scenarioDatainfo.scenarioNo,scenarioDatainfo.summary,scenarioDatainfo.message];
    return this._http.post(
      this.proxySetting.server + 'sendScenarioMail', { scenarioData },
      this.proxySetting.baseRequestOptions.clone({
        headers: this.proxySetting.headers,
      }),
    );
  }

  /**
   * ログインユーザーによってメッセージリストを表示する
   */ 
  DisplayScenarioMessage(): any {
    return this._http.post(
      this.proxySetting.server + 'DisplayScenarioMessage', { },
      this.proxySetting.baseRequestOptions.clone({
        headers: this.proxySetting.headers,
      }),
    );
  }

  /**
   * メッセージフラグを更新する
   */
  updateMessageFlag(msgId: number): any {
    const data = {
      msgId: msgId,
    };
    return this._http.post(
      this.proxySetting.server + 'updateMessageFlag', data,
      this.proxySetting.baseRequestOptions.clone({
        headers: this.proxySetting.headers,
      }),
    );
  }
}
