import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProxyService } from './proxy.service';

/**
 * レポートサーバへリクエストのサービス
 */
@Injectable()
export class UserActivityService {
  /** HttpリクエストのProxy設定 */
  protected proxySetting: any;

  constructor(private _http: HttpClient, protected proxyService: ProxyService) {
    this.proxySetting = this.proxyService.getProxySetting();
    this.proxySetting.headers = this.proxySetting.headers.append(
      'enctype',
      'multipart/form-data',
    );
    this.proxySetting.headers = this.proxySetting.headers.append(
      'X-Requested-With',
      'XMLHttpRequest',
    );
  }

  /**
   * ユーザーグループを取得する
   */
  getUserGroups(): any {
    const data = {};

    return this._http.post(
      this.proxySetting.server + 'getUserGroups',
      data,
      this.proxySetting.baseRequestOptions.clone({
        headers: this.proxySetting.headers,
      }),
    );
  }

  /**
   * 分析画面のユーザー行動履歴を取得する
   * @param userName 検索エリアで入力したユーザー名
   * @param groupId 検索エリアで入力したグループ
   * @param activityId 検索エリアで入力した行動種別
   * @param activityStart 検索エリアで入力した操作開始日付
   * @param activityEnd 検索エリアで入力した操作終了日付

   */
  getUserActivities(
    userName: string,
    groupId: string,
    activityId: string,
    activityStart: string,
    activityEnd: string,
  ): any {
    const data = {
      userName: userName,
      groupId: groupId,
      activityId: activityId,
      activityStart: activityStart,
      activityEnd: activityEnd,
    };
    return this._http.post(
      this.proxySetting.server + 'getUserActivities',
      data,
      this.proxySetting.baseRequestOptions.clone({
        headers: this.proxySetting.headers,
      }),
    );
  }
}
