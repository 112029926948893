import { Injectable } from '@angular/core';
import { HttpHeaders, HttpRequest } from '@angular/common/http';

/**
 * HttpリクエストのProxyサービス
 */
@Injectable()
export class ProxyService {
  /** Proxyサーバ */
  protected proxyServer = '/';
  /** Httpリクエストのヘッダ部 */
  protected headers: HttpHeaders = new HttpHeaders;
  /** Httpリクエストのオプション部*/
  protected baseRequestOptions: HttpRequest<any> = new HttpRequest('GET', '');
  /** ProxyのHttpリクエスト設定 */
  protected proxySetting = {
    server: this.proxyServer,
    headers: this.headers,
    baseRequestOptions: this.baseRequestOptions,
  };

  constructor() {
    this.headers = this.headers.append('Content-Type', 'application/json');
  }

  /**
   * HttpリクエストのProxy設定を取得
   */
  getProxySetting() {
    return this.proxySetting;
  }

  /**
   * HttpリクエストサーバのProxyを設定する
   */
  setProxyServer(proxy: any) {
    this.proxyServer = proxy;
  }
}
