import { Component, Input, OnInit, Inject, OnDestroy } from '@angular/core';
import { Location, DOCUMENT } from '@angular/common';
import { Router, NavigationStart } from '@angular/router';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserService } from '../../../@core/data/users.service';
import { SearchService } from '../../../@core/data/search.service';
import { ElasticsearchService } from '../../../@core/data/elasticsearch.service';
import { ConfigService } from '../../../@core/data/config.service';
import { ProxyService } from '../../../@core/data/proxy.service';
import { DatepickerService } from '../../../@core/data/datepicker.service';
import { LoadingService } from '../../../@core/data/loading.service';
import { MailService } from 'app/@core/data/mail.service';

import { pluck } from 'rxjs/operators';

// 暫定対処
const PAGE_TYPE_NAME = {
  'top': 'トップページ',
  'search': '検索ページ',
  'list': '一覧ページ',
  'detail': '商品詳細情報ページ',
  'overview': '商品概要情報ページ',
  'eum': 'エンドユーザ体感ページ',
};

/**
 * ヘッダコンポネント
 */
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  /** ヘッダの位置 */
  @Input() position = 'normal';
  /** ログインユーザー名 */
  user: any;
  /** ヘッダのユーザメニュー */
  items = [{ title: 'グループ設定' }, { title: 'Logout' }];
  /** フィルタのサイトリスト */
  siteList = [];
  /** フィルタのカテゴリリスト */
  categoryList = [];
  /** フィルタのページ種類リスト */
  pageTypeList = [];
  /** フィルタのシナリオ名リスト */
  scenarioNameList = [];
  /** フィルタのシナリオカテゴリーリスト */
  scenarioCategoryList = [];
  /** 選択したフィルタサイト */
  selectedSite: any = '*';
  /** 選択したフィルタシナリオ */
  selectedScenarioName: any = '*';
  /** 選択したフィルタシナリオカテゴリー */
  selectedScenarioCategory: any = '*';
  /** 選択したフィルタシナリオサイト */
  selectedScenarioSite: any = '*';
  /** 選択したフィルタデバイス */
  selectedDevice: any = 'mb';
  /** 日付指定の表示設定 */
  showDatePicker: boolean = false;
  /** データベースから取得した監視サイト設定一覧 */
  dbMonitoringConfigList = [];
  /** データベースから取得したシナリオ名一覧 */
  dbScenarioNameList = [];
  /** データベースから取得したシナリオカテゴリー一覧 */
  dbScenarioCategoryList = [];
  /** Subscribeオブジェクトリスト */
  subscriptions = [];
  /** 検索条件 */
  condition: any;
  /** 開始日付 */
  from: any;
  /** 終了日付 */
  now: any;
  /** サイドメニュールートリスト */
  routeList: any[];
  /** フィルタの選択可否設定 */
  disableSelectBox: boolean;
  /** 枠線を光らせる条件 */
  highlightBox: boolean = false;
  /** シナリオ概要より呼出した場合 */
  isScenario: boolean;
  /** シナリオ詳細より呼出した場合 */
  isScenarioDetail: boolean;
  /** 計測結果モニタリング画面 */
  isMonitor: boolean;

  selectedItem: string;
  /**  メッセージリストを空の配列として初期化する */
  messagelist: any[] = []; 

  constructor(
    @Inject(DOCUMENT) private document: any,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private location: Location,
    private router: Router,
    private userService: UserService,
    protected searchService: SearchService,
    protected elasticsearchService: ElasticsearchService,
    protected configService: ConfigService,
    protected proxyService: ProxyService,
    protected datepickerService: DatepickerService,
    protected loadingService: LoadingService,
    protected mailService: MailService,
  ) {
  }

  /**
   * コンポネント初期処理
   */
  ngOnInit() {
    this.subscriptions = [];
    this.condition = this.searchService.getCondition();
    this.from = this.condition.start;
    // 現在時刻
    this.now = this.condition.end;
    const vm = this;
    this.displayNotiMsgPanel(); // メッセージリストを取得

    this.userService.getUsers().subscribe(response => {
      if (response.role_id <= 3) {
        this.items.unshift({ title: 'エンジニア設定' });
      }
      this.items.unshift({ title: 'アカウント設定' });

      // ユーザーメニューのクリック処理
      this.subscriptions.push(this.menuService.onItemClick()
        .subscribe(menuOne => {
          if (menuOne && menuOne.item) {
            this.selectedItem = menuOne.item.title;
            if (this.selectedItem === 'Logout') {
              this.userService.logoutUser().pipe(pluck('success')).subscribe(success => {
                if (success === true) {
                  const proxySetting = this.proxyService.getProxySetting();
                  this.document.location.assign(proxySetting.server + 'home');
                }
              });
            }
            if (this.selectedItem === 'アカウント設定') {
              location.href = '/dashboard/synthetic#/pages/account-config';
            }
            if (this.selectedItem === 'エンジニア設定') {
              location.href = '/dashboard/synthetic#/pages/engineer-configs';
            }
            if (this.selectedItem === 'グループ設定') {
              if (response.role_id <= 3) {
                location.href = '/dashboard/synthetic#/pages/group-configs';
              } else {
                location.href = '/dashboard/synthetic#/pages/group-config';
              }
            }
          }
        }));
    });

    // UrlのrouteがEUMかSyntheticかを確認し、siteListをserver側のDBからフェッチする
    this.subscriptions.push(this.router.events.subscribe((val) => {
      // 開始イベント以外は拾わない
      if (!(val instanceof NavigationStart)) {
        return;
      }

      vm.refreshHeaderInfo(val.url);
    }));

    // SiteListが更新されたら、SearchServiceからサイトリスト再取得
    this.subscriptions.push(this.searchService.getSiteListConditionStatus().subscribe(res => {
      this.condition = this.searchService.getCondition();
      this.siteList = this.searchService.getAllSiteList();
    }));

    // CategoryListが更新されたら、SearchServiceからカテゴリリスト再取得
    this.subscriptions.push(this.searchService.getCategoryListConditionStatus().subscribe(res => {
      this.condition = this.searchService.getCondition();
      this.categoryList = this.searchService.getAllCategoryList();
    }));

    // PageTypeListが更新されたら、SearchServiceからページ種類リスト再取得
    this.subscriptions.push(this.searchService.getPageTypeListConditionStatus().subscribe(res => {
      this.condition = this.searchService.getCondition();
      this.pageTypeList = this.searchService.getAllPageTypeList();
      let name;
      this.pageTypeList.forEach(element => {
        name = PAGE_TYPE_NAME[element.ptype];
        if (!name) {
          name = '*';
        }
        element.name = name;
      });
    }));

    // カテゴリが選択された場合、フィルタのサイトリストを再作成
    this.subscriptions.push(this.searchService.getCategorySelectConditionStatus().subscribe(res => {
      this.selectedSite = '*';
      this.searchService.clearSiteList();
      this.condition = this.searchService.getCondition();
      this.createSiteList(vm);
    }));

    // ページ種類が選択された場合、フィルタのサイトリストを再作成
    this.subscriptions.push(this.searchService.getPageTypeSelectConditionStatus().subscribe(res => {
      this.selectedSite = '*';
      this.searchService.clearSiteList();
      this.condition = this.searchService.getCondition();
      this.createSiteList(vm);
    }));

    // シナリオ名が選択された場合、フィルタのシナリオカテゴリーを再作成
    this.subscriptions.push(this.searchService.getScenarioNameSelectConditionStatus().subscribe(res => {
      this.condition = this.searchService.getCondition();
      this.scenarioNameList = this.searchService.getAllScenarioNameList();
    }));

    // シナリオカテゴリーが選択された場合、フィルタのサイトリストを再作成
    this.subscriptions.push(this.searchService.getScenarioCategorySelectConditionStatus().subscribe(res => {
      this.condition = this.searchService.getCondition();
      this.scenarioCategoryList = this.searchService.getAllScenarioCategoryList();
    }));

    // シナリオサイトが選択された場合、フィルタのサイトリストを再作成
    this.subscriptions.push(this.searchService.getScenarioSiteSelectConditionStatus().subscribe(res => {
      this.condition = this.searchService.getCondition();
    }));

    // ドリルダウンされた場合、サイトリストフィルタの対象サイトを選択
    this.subscriptions.push(this.searchService.getDrillDownConditionStatus().subscribe(res => {
      this.selectedSite = this.searchService.getDrillDownOption();
      this.onSiteConditionSelect(this.selectedSite);
    }));

    // ドリルダウンされた場合、シナリオリストフィルタの対象シナリオを選択
    this.subscriptions.push(this.searchService.getScenarioSiteDrillDownConditionStatus().subscribe(res => {
      this.selectedScenarioSite = this.searchService.getScenarioSiteDrillDownOption();
    }));

    // ユーザー名を取得
    this.subscriptions.push(this.userService.getUsers()
      .subscribe((users: any) => this.user = users));

    this.refreshHeaderInfo();
  }

  /**
   * ヘッダ情報を初期化する。
   */
  refreshHeaderInfo(url: any = null): void {
    let route: any = '';
    let routeEnd: any = '';
    this.disableSelectBox = false;

    if (url) {
      this.routeList = url.split('/');
      route = this.routeList[2];
      routeEnd = this.routeList[this.routeList.length - 1];
    } else {
      if (this.location.path(true) !== '') {
        this.routeList = this.location.path(true).split('/');
        if (this.routeList.length > 5) {
          route = this.routeList[5];
        } else {
          route = this.routeList[4];
        }
        if (!route) {
          route = this.routeList[2];
        }
      }
    }

    this.isMonitor = true;

    // 設定画面ではモニタリング画面用のUIを表示しないよう設定
    const configPages = [
      'scenario-configs',
      'user-activities',
      'account-config',
      'engineer-configs',
      'engineer-group-config',
      'group-configs',
      'group-config',
      'user-configs',
    ];
    if (configPages.includes(route)) {
      this.isMonitor = false;
    }

    if (route === 'monitoring-config') {
      this.disableSelectBox = true;
      this.isMonitor = false;
    } else if (route === 'report') {
      this.disableSelectBox = true;
    }

    this.isScenario = false;
    this.isScenarioDetail = false;
    if (route.startsWith('scenario-summary')) {
      this.isMonitor = false;

      if (routeEnd.startsWith('detail')) {
        this.isScenarioDetail = true;   // シナリオ一覧
      } else {
        this.isScenario = true;　       // シナリオ詳細
      }

    } else {
      this.fetchUserConfig(this);
    }

    if (route === 'analysis') {
      this.highlightBox = true;
    } else {
      this.highlightBox = false;
    }
  }

  /**
   * コンポネント破棄時処理
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(function (value) {
      value.unsubscribe();
    });
  }

  /**
   * サイトメニューを開く・閉じる処理
   */
  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  /**
   * Home「Overview」へ移動
   */
  goToHome() {
    this.menuService.navigateHome();
  }

  /**
   * 期限フィルタの選択処理
   * @param val 対象期限
   */
  onConditionSelect(val) {
    if (val === 'custom') {
      this.datepickerService.setShowDatepicker(true);
      this.datepickerService.setDatepickerStatus();
      this.showDatePicker = true;
    } else {
      this.searchService.setSelectCondition(val);
      this.searchService.setConditionStatus();
      // datepickerを閉じる
      this.datepickerService.setShowDatepicker(false);
      this.datepickerService.setDatepickerStatus();
      this.showDatePicker = false;
    }
  }

  /**
   * サイトフィルタの選択処理
   * @param val 対象サイト
   */
  onSiteConditionSelect(val) {
    if (val !== '*') {
      const condition = [val];
      this.searchService.setSiteSelectCondition(condition);
    } else {
      this.searchService.clearSiteList();
    }
    this.searchService.setConditionStatus();
  }

  /**
   * カテゴリフィルタの選択処理
   * @param val 対象カテゴリ
   */
  onCategoryConditionSelect(val) {
    if (val !== '*') {
      const condition = [val];
      this.searchService.setCategorySelectCondition(condition);
    } else {
      this.searchService.clearCategoryList();
    }
    this.searchService.setCategorySelectConditionStatus();
    this.searchService.setConditionStatus();
  }

  /**
   * ページ種類フィルタの選択処理
   * @param val 対象ページ種類
   */
  onPageTypeConditionSelect(val) {
    if (val !== '*') {
      const condition = [val];
      this.searchService.setPageTypeSelectCondition(condition);
    } else {
      this.searchService.clearPageTypeList();
    }
    this.searchService.setPageTypeSelectConditionStatus();
    this.searchService.setConditionStatus();
  }

  /**
   * 端末種類フィルタの選択処理
   * @param val 端末種類
   */
  onDeviceConditionSelect(val) {
    const condition = [val];
    this.searchService.setDeviceSelectCondition(condition);
    this.searchService.setConditionStatus(); /* 検索条件が変更されたことを通知 - TODO 本当はsetDeviceSelectCondition内でやるのが良い */
  }

  /**
   * シナリオ名フィルタの選択処理
   * @param val 対象シナリオ
   */
  onScenarioNameConditionSelect(val) {
    if (val !== '*') {
      this.searchService.setScenarioNameSelectCondition(val);
    } else {
      this.searchService.clearScenarioNameList();
    }

    this.searchService.setScenarioNameSelectConditionStatus();
    this.searchService.setConditionStatus();
  }

  /**
   * シナリオカテゴリーフィルタの選択処理
   * @param val 対象ページ種類
   */
  onScenarioCategoryConditionSelect(val) {
    if (val !== '*') {
      this.searchService.setScenarioCategorySelectCondition(val);
    } else {
      this.searchService.clearScenarioCategoryList();
    }

    this.searchService.setScenarioCategorySelectConditionStatus();
    this.searchService.setConditionStatus();
  }

  /**
   * Server側のDBからUserConfigをフェッチ
   * @param vm コンポネントインスタンス
   */
  private fetchUserConfig(vm: any) {
    this.configService.getConfig().subscribe(response => {
      vm.dbMonitoringConfigList = response;

      this.createSiteList(vm);
      this.createCategoryList(vm);
      this.createPageTypeList(vm);

      this.searchService.setConditionStatus();
    });
  }

  /**
   * SiteListをフィルタ条件によって、DBデータリストから抽出する
   * @param vm コンポネントインスタンス
   * @param filterData 対象フィルタの値
   * @param filterType 対象フィルタタイプ
   */
  private getSiteList(vm: any, filterData: any, filterType: string) {
    const siteList = [];
    for (const config of vm.dbMonitoringConfigList) {
      switch (filterType) {
        case 'CatPage':
          if (config.category === filterData.category) {
            if (config.page_type === filterData.page_type) {
              siteList.push({
                name: config.page_name,
                url: config.url,
              });
            }
          }
          break;

        case 'CatOnly':
          if (config.category === filterData.category) {
            siteList.push({
              name: config.page_name,
              url: config.url,
            });
          }
          break;

        case 'PageOnly':
          if (config.page_type === filterData.page_type) {
            siteList.push({
              name: config.page_name,
              url: config.url,
            });
          }
          break;

        default:
          siteList.push({
            name: config.page_name,
            url: config.url,
          });
          break;
      }
    }
    return siteList;
  }

  /**
   * サイトリストフィルタの項目一覧を作成する
   * @param vm コンポネントオブジェクト
   */
  private createSiteList(vm: any) {
    let siteList = [];
    let filterType = '';
    let filterData: any;

    if (vm.condition.categoryList.length > 0) {
      if (vm.condition.pageTypeList.length > 0) {
        filterType = 'CatPage';

        filterData = {
          category: vm.condition.categoryList[0],
          page_type: vm.condition.pageTypeList[0],
        };
      } else {
        filterType = 'CatOnly';

        filterData = {
          category: vm.condition.categoryList[0],
        };
      }
    } else if (vm.condition.pageTypeList.length > 0) {
      filterType = 'PageOnly';

      filterData = {
        page_type: vm.condition.pageTypeList[0],
      };
    }

    siteList = this.getSiteList(vm, filterData, filterType);

    vm.searchService.switchSiteSelectionList(siteList);
    vm.searchService.setSiteListConditionStatus();
  }

  /**
   * カテゴリリストフィルタの項目一覧を作成する
   * @param vm コンポネントオブジェクト
   */
  private createCategoryList(vm: any) {
    const tmp_categoryList = [];
    const categoryList = [];

    if (vm.condition.categoryList.length === 0) {
      for (const config of vm.dbMonitoringConfigList) {
        if (tmp_categoryList.indexOf(config.category) === -1) {
          tmp_categoryList.push(config.category);
        }
      }

      for (const val of tmp_categoryList) {
        categoryList.push({
          category: val,
        });
      }
      vm.searchService.createCategorySelectionList(categoryList);
      vm.searchService.setCategoryListConditionStatus();
    }
  }

  /**
   * ページ種類リストフィルタの項目一覧を作成する
   * @param vm コンポネントオブジェクト
   */
  private createPageTypeList(vm: any) {
    const tmp_pageTypeList = [];
    const pageTypeList = [];

    if (vm.condition.pageTypeList.length === 0) {
      for (const config of vm.dbMonitoringConfigList) {
        if (tmp_pageTypeList.indexOf(config.page_type) === -1) {
          tmp_pageTypeList.push(config.page_type);
        }
      }

      for (const val of tmp_pageTypeList) {
        pageTypeList.push({
          ptype: val,
        });
      }
      vm.searchService.createPageTypeSelectionList(pageTypeList);
      vm.searchService.setPageTypeListConditionStatus();
    }
  }
  /**
   * ユーザーメニューのクリック処理
   * @param event クリックエベント
   */
  onMenuItemClick(event) {
    if (event.title === 'Log out') {
      this.userService.logoutUser().subscribe(response => {
        if (response === true) {
          const proxySetting = this.proxyService.getProxySetting();
          this.document.location.assign(proxySetting.server + 'home');
        }
      });
    }
  }

  // メッセージ通知パネルを表示する
  displayNotiMsgPanel() {
    const toggleNotificationButton = document.getElementById('toggleNotificationButton');
    const notificationPanel = document.getElementById('notificationPanel');
    this.mailService.DisplayScenarioMessage().subscribe((response) => {
      if (response.success) {
        this.messagelist = response.success.param;
      } 
    });
    
    if (toggleNotificationButton) {
      if (notificationPanel.style.right === '0px') {
          notificationPanel.style.right = '-400px'; // Slide out to the right
      } else {
          notificationPanel.style.right = '0px'; // Slide in from the right
      }
    }
  }

  // メッセージフラグを更新する
  changeMessageFlag(msgId:number,scenarioId:number){
    this.ngOnDestroy();
    this.searchService.setScenarioSiteDrillDownOption([scenarioId]);
    this.searchService.setScenarioSiteDrillDownConditionStatus();
    this.router.navigate(['/pages/scenario-summary/detail'], {
      queryParams: { selectedScenario: scenarioId, selectedCondition: 'custom' },
      state: { condition : { start: this.condition.start, end: this.condition.end } },
    });
    this.mailService.updateMessageFlag(msgId).subscribe((response) => {
      if (response.success) {
        this.messagelist = response.success.param;
        this.displayNotiMsgPanel();
      } 
      const notificationPanel = document.getElementById('notificationPanel');
      notificationPanel.style.right = '-400px'; // 右にスライドさせます
    });

  }
}
