import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProxyService } from './proxy.service';

/**
 * レポートサーバへリクエストのサービス
 */
@Injectable()
export class GroupConfigService {
  /** HttpリクエストのProxy設定 */
  protected proxySetting: any;

  constructor(private _http: HttpClient, protected proxyService: ProxyService) {
    this.proxySetting = this.proxyService.getProxySetting();
    this.proxySetting.headers = this.proxySetting.headers.append('enctype', 'multipart/form-data');
    this.proxySetting.headers = this.proxySetting.headers.append('X-Requested-With', 'XMLHttpRequest');
  }

  /**
   * グループ操作画面のグループ一覧を取得する
   * @param groupName 検索エリアで入力したグループ名
   * @param planId 検索エリアで入力したプラン
   * @param startDate 検索エリアで入力した利用期間開始日
   * @param endDate 検索エリアで入力した利用期間終了日
   */
  getGroupConfigs(groupName: string, planId: number, startDate: string, endDate: string): any {
    const data = {
      groupName: groupName,
      planId: planId,
      startDate: startDate,
      endDate: endDate,
    };

    return this._http.post(this.proxySetting.server + 'getGroupConfigs', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * グループ操作画面のユーザー一覧を取得する
   * @param groupId 設定したグループ名
   */
  getUserListConfigs(groupId: number): any {
    const data = {
      groupId: groupId,
    };
    return this._http.post(this.proxySetting.server + 'getUserListConfigs', data,
      this.proxySetting.baseRequestOptions.clone({ headers: this.proxySetting.headers }));
  }

  /**
   * 選択されたユーザー一覧を登録する
   * @param groupId 設定したグループ名
   * @param userIds 選択したユーザー一覧
   */
  addUsertoGroupConfigs(userIds: number[],groupId: number): any {
    const data = {
      userIds: userIds,
      groupId: groupId,
    };
    return this._http.post(this.proxySetting.server + 'addUsertoGroupConfigs', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * プランのリストを取得する
   */
  getPlans(): any {
    const data = {};

    return this._http.post(this.proxySetting.server + 'getPlans', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * グループ登録
   * @param data 送信するフォームデータ
   */
  addGroupConfigs(data: FormData): any {
    return this._http.post(this.proxySetting.server + 'addGroupConfig', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * グループ編集画面に表示するグループ情報を取得する
   * @param groupId 編集するグループのID
   */
  getGroupConfig(groupId: number): any {
    const data = {
      groupId: groupId,
    };

    return this._http.post(this.proxySetting.server + 'getGroupConfig', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * グループ更新
   * @param data 送信するフォームデータ
   */
  editGroupConfigs(data: FormData): any {
    return this._http.post(this.proxySetting.server + 'editGroupConfig', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * 指定したグループ情報と実行結果を削除する
   * @param groupId
   */
  deleteGroup(groupId: number): any {
    const data = {
      groupId: groupId,
    };

    return this._http.post(this.proxySetting.server + 'deleteGroup', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /** ユーザー設定画面に受け渡しするグループID */
  groupId;

  /**
   * 権限のリストを取得する
   */
  getUserRoles(): any {
    const data = {};

    return this._http.post(this.proxySetting.server + 'getGeneralRoles', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * ユーザー操作画面のユーザー一覧を取得する
   * @param roleId 検索エリアで入力した権限
   * @param userName 検索エリアで入力したユーザー名
   * @param groupId 検索エリアで入力したグループ
   * @param email 検索エリアで入力したメールアドレス
   */
  getUserConfigs(roleId: number, userName: string, email: string): any {
    const data = {
      roleId: roleId,
      userName: userName,
      groupId: this.groupId,
      email: email,
    };

    return this._http.post(this.proxySetting.server + 'getUserConfigs', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * ユーザー登録
   * @param data 送信するフォームデータ
   */
  addUserConfigs(data: FormData): any {
    return this._http.post(this.proxySetting.server + 'addUserConfig', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * ユーザー編集画面に表示するユーザー情報を取得する
   * @param userId 編集するユーザーのID
   */
  getUserConfig(userId: number): any {
    const data = {
      userId: userId,
    };

    return this._http.post(this.proxySetting.server + 'getUserConfig', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * ユーザー更新
   * @param data 送信するフォームデータ
   */
  editUserConfigs(data: FormData): any {
    return this._http.post(this.proxySetting.server + 'editUserConfig', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * 指定したユーザー情報と実行結果を削除する
   * @param userId
   */
  deleteUser(userId: number): any {
    const data = {
      userId: userId,
    };

    return this._http.post(this.proxySetting.server + 'deleteUser', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * パスワード更新
   * @param data 送信するフォームデータ
   */
  editUserPassword(data: FormData): any {
    return this._http.post(this.proxySetting.server + 'editUserPassword', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }
}
