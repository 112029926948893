import { Injectable } from '@angular/core';
import { NbThemeService } from '@nebular/theme';

/**
 * Overviewのスレッショルドサービス
 */
@Injectable()
export class ThresholdService {
  /** スレッショルドの色 */
  private colors: any;
  /** スレッショルドの設定 */
  private condition: any = {
    'other': 'danger',
    'fmp': {
      order: 'asc',
      settings: [{
        threshold: 500,
        color: 'info',
      }, {
        threshold: 1000,
        color: 'success',
      }, {
        threshold: 2000,
        color: 'warning',
      }],
    },
    'eum': {
      order: 'asc',
      settings: [{
        threshold: 1000,
        color: 'info',
      }, {
        threshold: 5000,
        color: 'success',
      }, {
        threshold: 10000,
        color: 'warning',
      }],
    },
    'psi': {
      order: 'desc',
      settings: [{
        threshold: 90,
        color: 'info',
      }, {
        threshold: 70,
        color: 'success',
      }, {
        threshold: 40,
        color: 'warning',
      }],
    },
    'scenario': {
      order: 'asc',
      settings: [{
        threshold: 500,
        color: 'info',
      }, {
        threshold: 1000,
        color: 'success',
      }, {
        threshold: 2000,
        color: 'warning',
      }],
    },
  };

  constructor(private theme: NbThemeService) {
    const vm = this;
    this.theme.getJsTheme().subscribe(config => {
      vm.colors = config.variables;
    });
  }

  /**
   * Overviewのスレッショルド値により、画面の表示色を取得
   * @param target 対象データ
   * @param value スレッショルド値
   */
  getValueColor(target: string, value: number) {
    const targetCondition = this.condition[target];
    if (!targetCondition) {
      return this.colors[this.condition.other];
    }
    if (targetCondition.order === 'asc') {
      return this.colors[this.getValueColorAsc(value, targetCondition.settings)];
    } else {
      return this.colors[this.getValueColorDesc(value, targetCondition.settings)];
    }
  }

  /**
   * 画面の表示色を昇順で取得
   * @param value スレッショルド値
   * @param settings スレッショルド設定
   */
  private getValueColorAsc(value: number, settings: any) {
    for (const setting of settings) {
      if (value < setting.threshold) {
        return setting.color;
      }
    }
    return this.condition.other;
  }

  /**
   * 画面の表示色を降順で取得
   * @param value スレッショルド値
   * @param settings スレッショルド設定
   */
  private getValueColorDesc(value: number, settings: any) {
    for (const setting of settings) {
      if (value > setting.threshold) {
        return setting.color;
      }
    }
    return this.condition.other;
  }
}
