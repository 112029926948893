import { Component, Input, Output, EventEmitter, Injectable } from '@angular/core';
import * as moment from 'moment';
import { DatepickerService } from '../../../@core/data/datepicker.service';
import { SearchService } from '../../../@core/data/search.service';
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap'; // edited by EMK
/**
 * カスタム日付指定コンポネント
 */
/* Edited by EMK Start */
const I18N_VALUES = {
  ja: {
    weekdays: [ '月', '火', '水', '木', '金', '土', '日'],
    months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
  },
};

@Injectable()
export class I18n {
  language = 'ja';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) {
    super();
  }
  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
/* Edited by EMK End */
@Component({
  selector: 'ngx-datepicker',
  styleUrls: ['./datepicker.component.scss'],
  templateUrl: './datepicker.component.html',
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}], // Edited by EMK
})
export class DatepickerComponent {
  @Input() position = '';

  @Input() showDatepicker: string;

  @Output() setPeriod = new EventEmitter();

  @Output() setSelectionPeriod = new EventEmitter();

  /** 開始日付 **/
  start: any;
  /** 終了日付 **/
  end: any;


  constructor(
    protected datepickerService: DatepickerService,
    protected searchService: SearchService,
  ) {
    const today = new Date();
    this.end = {year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate()};
    // this.strToday = today.toLocaleDateString(); // yyyy/MM/dd
    // this.strToday = today.toISOString().split('T')[0]; // yyyy-MM-dd
    today.setDate(today.getDate() - 7);
    this.start = {year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate()};
    // this.str7daysBefore = today.toLocaleDateString();
    // this.str7daysBefore = today.toISOString().split('T')[0];
  } // a

  /**
   * 開始日付を取得
   */
  getStartDate() {
    return this.start;
  }

  /**
   * 終了日付を取得
   */
  getEndDate() {
    return this.end;
  }

  /**
   * カスタム日付指定でデータを検索
   */
  customSearch() {
    if (this.position === 'header') {
      this.datepickerService.setShowDatepicker(false);
      this.searchService.setCustomSelectCondition(this.start, this.end);
      this.datepickerService.setDatepickerStatus();
      this.searchService.setConditionStatus();
    } else {

      const startDate = {
        'year': this.start.year,
        'month': this.start.month - 1,
        'day': this.start.day,
        'hour': this.start.hour,
        'minute': this.start.minute,
        'second': this.start.second,
      };
      const endDate = {
        'year': this.end.year,
        'month': this.end.month - 1,
        'day': this.end.day,
        'hour': 23,
        'minute': 59,
        'second': 59,
      };

      this.setPeriod.emit({start: moment(startDate).valueOf(), end: moment(endDate).valueOf()});
      this.setSelectionPeriod.emit({start: moment(startDate), end: moment(endDate)});
    }
  }

  /**
   * 日付指定コンポネントを非表示する
   */
  closeDatepicker() {
    this.showDatepicker = 'close_datepicker';
    this.datepickerService.setShowDatepicker(false);
    this.datepickerService.setDatepickerStatus();
  }
}
