import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProxyService } from './proxy.service';

import { Subject } from 'rxjs';

/**
 * レポートサーバへリクエストのサービス
 */
@Injectable()
export class ReportService {
  /** HttpリクエストのProxy設定 */
  protected proxySetting: any;
  /** レスポンス状態のObserver */
  protected responseStatus: Subject<any> = new Subject();
  /** レポート結果のObserver */
  protected showResultStatus: Subject<boolean> = new Subject();

  constructor(private _http: HttpClient, protected proxyService: ProxyService) {
    this.proxySetting = this.proxyService.getProxySetting();
  }

  /**
   * レスポンスの状態を設定
   */
  setResponseStatus(response) {
    this.responseStatus.next(response);
  }

  /**
   * レスポンス状態のObserverを取得
   */
  getResponseStatus() {
    return this.responseStatus;
  }

  /**
   * レポートの結果の状態を設定
   */
  setShowResultStatus(showResult) {
    this.showResultStatus.next(showResult);
  }

  /**
   * レポートの結果のObserverを取得
   */
  getShowResultStatus() {
    return this.showResultStatus;
  }

  /**
   * レポートのクエリをサーバ側へリクエスト
   */
  requestReport(url: string, range_from: string, range_to: string) {
    const reportCondition = {
        'targetUrl': url,
        'range_from': range_from,
        'range_to': range_to,
    };
    return this._http.post(this.proxySetting.server + 'report', reportCondition,
                this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  requestLightHouseReports(url: string, strategy: string) {
    const requrl = 'https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=' + 
                    url + '&strategy=' + strategy + '&locale=ja';
    return this._http.get(requrl);
  }
}
