import { Component, OnDestroy } from '@angular/core';
import {
  NbMediaBreakpoint,
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';

import { StateService } from '../../../@core/data/state.service';
import { DatepickerService } from '../../../@core/data/datepicker.service';

import { Subscription } from 'rxjs';
import {withLatestFrom, delay} from 'rxjs/operators';



/**
 * レーアウトコンポネント
 */
@Component({
  selector: 'ngx-app-layout',
  styleUrls: ['./app-layout.component.scss'],
  template: `
    <nb-layout [center]="layout.id === 'center-column'" >
      <nb-layout-header fixed>
        <div class="header-comp">
          <ngx-header [position]="sidebar.id === 'left' ? 'normal': 'inverse'"></ngx-header>
        </div>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar"
                   tag="menu-sidebar"
                   responsive
                   [right]="sidebar.id === 'right'">
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column class="main-content">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class AppLayoutComponent  implements OnDestroy {
  layout: any = {};
  sidebar: any = {};

  protected layoutState$: Subscription;
  protected sidebarState$: Subscription;
  protected menuClick$: Subscription;
  showDatePicker: boolean;

  constructor(protected stateService: StateService,
              protected menuService: NbMenuService,
              protected themeService: NbThemeService,
              protected bpService: NbMediaBreakpointsService,
              protected sidebarService: NbSidebarService,
              protected datepickerService: DatepickerService,
             ) {
    this.layoutState$ = this.stateService.onLayoutState()
      .subscribe((layout: string) => this.layout = layout);

    this.sidebarState$ = this.stateService.onSidebarState()
      .subscribe((sidebar: string) => {
        this.sidebar = sidebar;
      });

    const isBp = this.bpService.getByName('is');
    this.menuClick$ = this.menuService.onItemSelect().pipe(
      withLatestFrom(this.themeService.onMediaQueryChange()),
      delay(20))
      .subscribe(([item, [bpFrom, bpTo]]: [any, [NbMediaBreakpoint, NbMediaBreakpoint]]) => {

        if (bpTo.width <= isBp.width) {
          this.sidebarService.collapse('menu-sidebar');
        }
      });

    this.datepickerService.getDatepickerStatus().subscribe(res => {
      this.showDatePicker = this.datepickerService.getShowDatepicker();
    });
  }

  /**
   * コンポコンポネント破棄時処理
   */
  ngOnDestroy() {
    this.layoutState$.unsubscribe();
    this.sidebarState$.unsubscribe();
    this.menuClick$.unsubscribe();
  }
}
