import { Injectable } from '@angular/core';

import { Observable ,  BehaviorSubject, of } from 'rxjs';


/**
 * レーアウト、サイドバーのサービス
 */
@Injectable()
export class StateService {
  /** レーアウトの設定 */
  protected layouts: any = [
    {
      name: 'One Column',
      icon: 'nb-layout-default',
      id: 'one-column',
      selected: true,
    },
  ];
  /** サイドバーの設定 */
  protected sidebars: any = [
    {
      name: 'Left Sidebar',
      icon: 'nb-layout-sidebar-left',
      id: 'left',
      selected: true,
    },
  ];
  /** レーアウトの状態Observer */
  protected layoutState$ = new BehaviorSubject(this.layouts[0]);
  /** サイドバーの状態Observer */
  protected sidebarState$ = new BehaviorSubject(this.sidebars[0]);

  /**
   * レーアウトの状態を設定
   * @param state ステート
   */
  setLayoutState(state: any): any {
    this.layoutState$.next(state);
  }

  /**
   * レーアウトの状態Observerを取得
   */
  getLayoutStates(): Observable<any[]> {
    return of(this.layouts);
  }

  /**
   * 現在のレーアウト状態Observerを取得
   */
  onLayoutState(): Observable<any> {
    return this.layoutState$.asObservable();
  }

  /**
   * サイドバーの状態Observerを取得
   * @param state ステート
   */
  setSidebarState(state: any): any {
    this.sidebarState$.next(state);
  }

  /**
   * サイドバーの状態Observerを取得
   */
  getSidebarStates(): Observable<any[]> {
    return of(this.sidebars);
  }

  /**
   * 現在のサイドバー状態Observerを取得
   */
  onSidebarState(): Observable<any> {
    return this.sidebarState$.asObservable();
  }
}
