import { Component } from '@angular/core';

/**
 * フッターコンポネント
 */
@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
    <b>Copyright © <a href="https://www.airitech.co.jp/" target="_blank">Airitech</a> All Rights Reserved.</b>
    </span>
    <div class="socials">
      <a href="https://www.airitech.co.jp/" target="_blank">
        <img src="assets/img/company-logo.png">
      </a>
    </div>
  `,
})
export class FooterComponent {
}
