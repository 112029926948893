import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProxyService } from './proxy.service';

/**
 * レポートサーバへリクエストのサービス
 */
@Injectable()
export class EngineerConfigService {
  /** HttpリクエストのProxy設定 */
  protected proxySetting: any;

  constructor(private _http: HttpClient, protected proxyService: ProxyService) {
    this.proxySetting = this.proxyService.getProxySetting();
    this.proxySetting.headers = this.proxySetting.headers.append('enctype', 'multipart/form-data');
    this.proxySetting.headers = this.proxySetting.headers.append('X-Requested-With', 'XMLHttpRequest');
  }

  /**
   * 権限のリストを取得する
   */
  getEngineerRoles(): any {
    const data = {};

    return this._http.post(this.proxySetting.server + 'getEngineerRoles', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * グループのリストを取得する
   */
  getGroups(): any {
    const data = {};

    return this._http.post(this.proxySetting.server + 'getGroups', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * ユーザー操作画面のユーザー一覧を取得する
   * @param roleId 検索エリアで入力した権限
   * @param userName 検索エリアで入力したユーザー名
   * @param groupId 検索エリアで入力したグループ
   */
  getEngineerConfigs(roleId: number, userName: string, groupId: number): any {
    const data = {
      roleId: roleId,
      userName: userName,
      groupId: groupId,
    };

    return this._http.post(this.proxySetting.server + 'getEngineerConfigs', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * ユーザー登録
   * @param data 送信するフォームデータ
   */
  addEngineerConfigs(data: FormData): any {
    return this._http.post(this.proxySetting.server + 'addEngineerConfig', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * ユーザー編集画面に表示するユーザー情報を取得する
   * @param userId 編集するユーザーのID
   */
  getEngineerConfig(userId: number): any {
    const data = {
      userId: userId,
    };

    return this._http.post(this.proxySetting.server + 'getEngineerConfig', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * ユーザー更新
   * @param data 送信するフォームデータ
   */
  editEngineerConfigs(data: FormData): any {
    return this._http.post(this.proxySetting.server + 'editEngineerConfig', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * 指定したユーザー情報と実行結果を削除する
   * @param userId
   */
  deleteEngineer(userId: number): any {
    const data = {
      userId: userId,
    };

    return this._http.post(this.proxySetting.server + 'deleteEngineer', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * パスワード更新
   * @param data 送信するフォームデータ
   */
  editEngineerPassword(data: FormData): any {
    return this._http.post(this.proxySetting.server + 'editEngineerPassword', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /** 担当グループ設定用のエンジニアID */
  engineerId;

  /**
   * 権限のリストを取得する
   */
  getPlans(): any {
    const data = {};

    return this._http.post(this.proxySetting.server + 'getPlans', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * 指定したユーザーIDのグループを取得する
   * @param userId
   */
  getEngineerGroups(userId): any {
    const data = {
      userId: userId,
    };

    return this._http.post(this.proxySetting.server + 'getEngineerGroups', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * 指定したユーザーと現在紐付いていないグループを取得する
   * @param data
   */
  getNotEngineerGroups(data: FormData): any {
    return this._http.post(this.proxySetting.server + 'getNotEngineerGroups', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  addEngineerGroup(userId, groupId): any {
    const data = {
      userId: userId,
      groupId: groupId,
    };

    return this._http.post(this.proxySetting.server + 'addEngineerGroup', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * 指定したユーザーとグループの紐付けを削除する
   * @param userId
   * @param groupId
   */
  deleteEngineerGroup(userId, groupId): any {
    const data = {
      userId: userId,
      groupId: groupId,
    };

    return this._http.post(this.proxySetting.server + 'deleteEngineerGroup', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }
}
