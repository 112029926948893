import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { SearchService } from './search.service';
import { ProxyService } from './proxy.service';

import { Scenario } from '../../models/scenario.model';

/**
 * Elasticsearchへのクエリリクエストサービス
 */
@Injectable()
export class ElasticsearchService {
  /** HTTPリクエストProxy設定 */
  protected proxySetting: any;
  /** 検索条件 */
  private searchCondition: any;

  // 改修後シナリオデータ
  private scenario: Subject<Scenario> = new Subject();

  constructor(protected searchService: SearchService, protected proxyService: ProxyService, private _http: HttpClient) {
    this.proxySetting = this.proxyService.getProxySetting();

    this.searchCondition = this.searchService.getCondition();
    this.searchService.getConditionState().subscribe(res => {
      this.searchCondition = this.searchService.getCondition();
    });
  }

  getScenario(): Subject<Scenario> {
    return this.scenario;
  }

  getScenarioSummary(queryScenarioStep: any,
                     queryScenarioPageTransition: any,
                     queryScenarioLighthouseReport: any,
                     queryScenarioPageContent: any,
                     scenarioName?: any,
                     category?: any,
                     scenarioSite?: any): any {
    const data = {
      queryScenarioStep: queryScenarioStep,
      queryScenarioPageTransition: queryScenarioPageTransition,
      queryScenarioLighthouseReport: queryScenarioLighthouseReport,
      queryScenarioPageContent: queryScenarioPageContent,
      capture: 0,
      scenarioName: scenarioName,
      category: category,
      scenarioSite: scenarioSite,
    };
    return this._http.post(
      this.proxySetting.server + 'getScenarioSummary', data,
      this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  getScenarioDetail(queryScenarioStep: any,
                    queryScenarioPageTransition: any,
                    queryScenarioLighthouseReport: any,
                    queryScenarioPageContent: any,
                    scenarioSite?: any,
                    competitorsFlg: any = 0): any {
    const data = {
      queryScenarioStep: queryScenarioStep,
      queryScenarioPageTransition: queryScenarioPageTransition,
      queryScenarioLighthouseReport: queryScenarioLighthouseReport,
      queryScenarioPageContent: queryScenarioPageContent,
      capture: 1,
      scenarioSite: scenarioSite,
      competitorsFlg: competitorsFlg,
      scenarioDetail: true,
    };
    return this._http.post(
      this.proxySetting.server + 'getScenarioSummary', data,
      this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * グラフ等のElasticsearch検索クエリリクエスト
   * @param query 対応クエリ
   */
  searchWithProxy(query: any, replacePgNameFlag: boolean = true): any {
    const data = {
      query: query,
      category: [],
      pageType: [],
      siteList: [],
      replacePgNameFlag: replacePgNameFlag,
    };

    this.addFilter(data);

    return this._http.post(
      this.proxySetting.server + 'search', data,
      this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * スクリーンキャプチャのElasticsearch検索クエリリクエスト
   * @param query 対象クエリ
   */
  searchScreenshotsWithProxy(query: any, replacePgNameFlag: boolean = true, reportSearchFlag: boolean = false): any {
    const data = {
      query: query,
      category: [],
      pageType: [],
      siteList: [],
      replacePgNameFlag: replacePgNameFlag,
      reportSearchFlag: reportSearchFlag,
    };

    this.addFilter(data);

    return this._http.post(
      this.proxySetting.server + 'searchScreenshots', data,
      this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  searchEumWithProxy(query: any, replacePgNameFlag: boolean = true, targetPage: any): any {
    const data = {
      query: query,
      category: [],
      pageType: [],
      siteList: [],
      replacePgNameFlag: replacePgNameFlag,
      targetPage: targetPage,
    };

    this.addFilter(data);

    return this._http.post(
      this.proxySetting.server + 'searchEum', data,
      this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  private addFilter(data: any) {
    if (this.searchCondition.categoryList.length !== 0) {
      for (const category of this.searchCondition.categoryList) {
        data.category.push(category);
      }
    }

    if (this.searchCondition.pageTypeList.length !== 0) {
      for (const pageType of this.searchCondition.pageTypeList) {
        data.pageType.push(pageType);
      }
    }

    if (this.searchCondition.siteList.length !== 0) {
      for (const site of this.searchCondition.siteList) {
        data.siteList.push(site);
      }
    }
  }

  private addScenarioFilter(data: any) {
    if (this.searchCondition.scenarioSite.length !== 0) {
      for (const scenario of this.searchCondition.scenarioSite) {
        data.scenarioSite.push(scenario);
      }
    }
  }

  /**
   * 線グラフのElasticsearch検索クエリ（シナリオ）リクエスト
   * @param query 対応クエリ
   */
  searchScenarioLineChartWithProxy(query: any, contentquery: any, metricName: any): any {
    const data = {
      query: query,
      contentquery: contentquery,
      metricName: metricName,
      scenarioType: [],
    };

    return this._http.post(
      this.proxySetting.server + 'searchScenarioLineChart', data,
      this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * 積上線グラフのElasticsearch検索クエリ（シナリオ）リクエスト
   * @param query 対応クエリ
   */
  searchScenarioAreaStackWithProxy(query: any): any {
    const data = {
      query: query,
      scenarioType: [],
    };

    this.addScenarioFilter(data);

    return this._http.post(
      this.proxySetting.server + 'searchScenarioAreaStackChart', data,
      this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  searchPageLoadTime(query:any):any{
    const data={
      query:query
    }
    return this._http.post(
      this.proxySetting.server + 'searchPageLoadTime', data,
      this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }
}
