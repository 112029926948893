import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';

/**
 * グラフ、フィルタ等のデータ検索・更新サービス
 */
@Injectable()
export class SearchService {
  /** 検索条件（default値：期限＝7日、端末＝携帯） */
  protected condition: any = {
    text: '',
    start: moment().subtract(7, 'day').valueOf(),
    end: moment().valueOf(),
    siteList: [],
    categoryList: [],
    pageTypeList: [],
    device: 'mobile',
    scenarioPageType: [],
    scenarioName: [],
    scenarioCategory: [],
    scenarioSite: [],
  };

  /** ドリルダウン設定 */
  private drillDownOption: any;
  /** EUMドリルダウン設定 */
  private eumDrillDownOption: any;
  /** シナリオドリルダウン設定 */
  private scenarioSiteDrillDownOption: any;
  /** サイトフィルタリスト */
  private allSiteList: any = [];
  /** サイトマップ（キー:url,値:ページ名） */
  private allSiteListMap: any = {};
  /** サイトマップ（キー:ページ名,値:url） */
  private allSiteNameMap: any = {};
  /** カテゴリフィルタリスト */
  private allCategoryList: any = [];
  /** ページ種類フィルタリスト */
  private allPageTypeList: any = [];
  /** シナリオ名フィルタリスト */
  private allScenarioNameList: any = [];
  /** シナリオカテゴリーフィルタリスト */
  private allScenarioCategoryList: any = [];
  /** シナリオサイトフィルタリスト */
  private allScenarioSiteList: any = [];
  /** 検索条件Observer */
  protected conditionStatus: Subject<boolean> = new Subject();
  /** ドリルダウンObserver */
  protected drillDownConditionStatus: Subject<boolean> = new Subject();
  /** EUMドリルダウンObserver */
  protected eumDrillDownConditionStatus: Subject<boolean> = new Subject();
  /** シナリオサイトドリルダウンObserver */
  protected scenarioSiteDrillDownConditionStatus: Subject<boolean> = new Subject();
  /** サイトフィルタリストObserver */
  protected siteListConditionStatus: Subject<boolean> = new Subject();
  /** カテゴリフィルタリストObserver */
  protected categoryListConditionStatus: Subject<boolean> = new Subject();
  /** ページ種類フィルタリストObserver */
  protected pageTypeListConditionStatus: Subject<boolean> = new Subject();
  /** 対象カテゴリフィルタObserver */
  protected catSelectConditionStatus: Subject<boolean> = new Subject();
  /** 対象ページ名カテゴリフィルタObserver */
  protected pageSelectConditionStatus: Subject<boolean> = new Subject();
  /** 対象シナリオ名フィルタObserver */
  protected scenarioNameSelectConditionStatus: Subject<boolean> = new Subject();
  /** 対象シナリオカテゴリーフィルタObserver */
  protected scenarioCategorySelectConditionStatus: Subject<boolean> = new Subject();
  /** 対象シナリオサイトフィルタObserver */
  protected scenarioSiteSelectConditionStatus: Subject<boolean> = new Subject();
  /** EUMリクエスト数・レスポンスタイムフィルタObserver */
  protected eumReqResConditionStatus: Subject<any> = new Subject();
  /** 概要ページのEUMクエリ検索Observer */
  protected overviewEumStatus: Subject<boolean> = new Subject();

  /** 検索条件Observerの状態を設定 */
  setConditionStatus(): any {
    this.conditionStatus.next();
  }

  /** サイトリストフィルタObserverの状態を設定 */
  setSiteListConditionStatus(): any {
    this.siteListConditionStatus.next();
  }

  /** カテゴリリストフィルタObserverの状態を設定 */
  setCategoryListConditionStatus(): any {
    this.categoryListConditionStatus.next();
  }

  /** ページ名リストフィルタObserverの状態を設定 */
  setPageTypeListConditionStatus(): any {
    this.pageTypeListConditionStatus.next();
  }

  /** 対象カテゴリフィルタObserverの状態を設定 */
  setCategorySelectConditionStatus(): any {
    this.catSelectConditionStatus.next();
  }

  /** 対象ページ名フィルタObserverの状態を設定 */
  setPageTypeSelectConditionStatus(): any {
    this.pageSelectConditionStatus.next();
  }

  /** 対象シナリオ名フィルタObserverの状態を設定 */
  setScenarioNameSelectConditionStatus(): any {
    this.scenarioNameSelectConditionStatus.next();
  }

  /** 対象シナリオカテゴリーフィルタObserverの状態を設定 */
  setScenarioCategorySelectConditionStatus(): any {
    this.scenarioCategorySelectConditionStatus.next();
  }

  /** 対象シナリオサイトフィルタObserverの状態を設定 */
  setScenarioSiteSelectConditionStatus(): any {
    this.scenarioSiteSelectConditionStatus.next();
  }

  /** ドリルダウンObserverの状態を設定 */
  setDrillDownConditionStatus(): any {
    this.drillDownConditionStatus.next();
  }

  /** EUMドリルダウンObserverの状態を設定 */
  setEumDrillDownConditionStatus(): any {
    this.eumDrillDownConditionStatus.next();
  }

  /** ドリルダウンObserverの状態を設定 */
  setScenarioSiteDrillDownConditionStatus(): any {
    this.scenarioSiteDrillDownConditionStatus.next();
  }

  /** EUMリクエスト数・レスポンスタイムフィルタObserverの状態を設定 */
  setEumReqResConditionStatus(reqResFilterCondition: any): any {
    this.eumReqResConditionStatus.next(reqResFilterCondition);
  }

  /** 概要ページのEUM検索Observerの状態を設定 */
  setOverviewEumStatus(): any {
    this.overviewEumStatus.next();
  }

  /** ドリルダウンオプションを設定 */
  setDrillDownOption(val: String): any {
    this.drillDownOption = val;
  }

  /** EUMドリルダウンオプションを設定 */
  setEumDrillDownOption(val: any): any {
    this.eumDrillDownOption = val;
  }

  /** シナリオサイトドリルダウンオプションを設定 */
  setScenarioSiteDrillDownOption(val: any): any {
    this.scenarioSiteDrillDownOption = val;
  }

  /** 検索条件Observerを取得 */
  getConditionState(): any {
    return this.conditionStatus;
  }

  /** サイトリストフィルタObserverを取得 */
  getSiteListConditionStatus(): any {
    return this.siteListConditionStatus;
  }

  /** カテゴリリストフィルタObserverを取得 */
  getCategoryListConditionStatus(): any {
    return this.categoryListConditionStatus;
  }

  /** ページ名リストフィルタObserverを取得 */
  getPageTypeListConditionStatus(): any {
    return this.pageTypeListConditionStatus;
  }

  /** 対象シナリオ名フィルタObserverを取得 */
  getScenarioNameSelectConditionStatus(): any {
    return this.scenarioNameSelectConditionStatus;
  }

  /** 対象シナリオカテゴリーフィルタObserverを取得 */
  getScenarioCategorySelectConditionStatus(): any {
    return this.scenarioCategorySelectConditionStatus;
  }

  /** 対象シナリオサイトフィルタObserverを取得 */
  getScenarioSiteSelectConditionStatus(): any {
    return this.scenarioSiteSelectConditionStatus;
  }

  /** 対象カテゴリフィルタObserverを取得 */
  getCategorySelectConditionStatus(): any {
    return this.catSelectConditionStatus;
  }

  /** 対象ページ名フィルタObserverを取得 */
  getPageTypeSelectConditionStatus(): any {
    return this.pageSelectConditionStatus;
  }

  /** ドリルダウンObserverを取得 */
  getDrillDownConditionStatus(): any {
    return this.drillDownConditionStatus;
  }

  /** EUMドリルダウンObserverを取得 */
  getEumDrillDownConditionStatus(): any {
    return this.eumDrillDownConditionStatus;
  }

  /** シナリオサイトドリルダウンObserverを取得 */
  getScenarioSiteDrillDownConditionStatus(): any {
    return this.scenarioSiteDrillDownConditionStatus;
  }

  /** EUMリクエスト数・レスポンスタイムフィルタObserverを取得 */
  getEumReqResConditionStatus(): any {
    return this.eumReqResConditionStatus;
  }

  /** 概要ページのEUM検索Observerを取得 */
  getOverviewEumStatus(): any {
    return this.overviewEumStatus;
  }

  /** ドリルダウン設定を取得 */
  getDrillDownOption() {
    return this.drillDownOption;
  }

  /** EUMドリルダウン設定を取得 */
  getEumDrillDownOption() {
    return this.eumDrillDownOption;
  }

  /** シナリオサイトドリルダウン設定を取得 */
  getScenarioSiteDrillDownOption() {
    return this.scenarioSiteDrillDownOption;
  }

  /** サイトマップ（キー：ページ名、値：url）を取得 */
  getAllSiteNameMap() {
    return this.allSiteNameMap;
  }

  /** 検索条件を設定 */
  setCondition(condition: any): any {
    this.condition = condition;
  }

  /** 簡易検索期間を検索条件に設定 */
  setSelectCondition(condition: string): any {
    this.condition = { // TODO this.condition.startとendに上書き設定するだけで済むのでは？
      text: '',
      start: moment().subtract(parseInt(condition, 10), 'minute').valueOf(),
      end: moment().valueOf(),
      siteList: this.condition.siteList,
      categoryList: this.condition.categoryList,
      pageTypeList: this.condition.pageTypeList,
      device: this.condition.device,
      scenarioPageType: this.condition.scenarioPageType,
      scenarioName: this.condition.scenarioName,
      scenarioCategory: this.condition.scenarioCategory,
      scenarioSite: this.condition.scenarioSite,
    };
  }

  /** カスタム日付指定期間を検索条件に設定 */
  setCustomSelectCondition(startDate: any, endDate: any): any {
    startDate.month = startDate.month - 1;
    endDate.month = endDate.month - 1;
    endDate = {
      'year': endDate.year,
      'month': endDate.month,
      'day': endDate.day,
      'hour': 23,
      'minute': 59,
      'second': 59,
    };
    this.condition = { // TODO this.condition.startとendに上書き設定するだけで済むのでは？
      text: '',
      start: moment(startDate).valueOf(),
      end: moment(endDate).valueOf(),
      siteList: this.condition.siteList,
      categoryList: this.condition.categoryList,
      pageTypeList: this.condition.pageTypeList,
      device: this.condition.device,
      scenarioPageType: this.condition.scenarioPageType,
      scenarioName: this.condition.scenarioName,
      scenarioCategory: this.condition.scenarioCategory,
      scenarioSite: this.condition.scenarioSite,
    };
  }

  /** 検索条件を取得 */
  getCondition(): any {
    return this.condition;
  }

  /** サイトリストフィルタを取得 */
  getAllSiteList(): any {
    return this.allSiteList;
  }

  /** 対象サイトを検索条件に設定 */
  setSiteSelectCondition(filters: Array<string>) {
    // 検索対象としたいサイト一覧を設定する。
    this.condition.siteList = [];
    for (const filter of filters) {
      this.condition.siteList.push(this.allSiteListMap[filter].url);
    }
  }

  /** カテゴリリストフィルタを取得 */
  getAllCategoryList(): any {
    return this.allCategoryList;
  }

  /** 対象カテゴリを検索条件に設定 */
  setCategorySelectCondition(filters: Array<string>) {
    this.condition.categoryList = [];
    for (const filter of filters) {
      this.condition.categoryList.push(filter);
    }
  }

  /** 対象シナリオを検索条件に設定 */
  setScenarioNameSelectCondition(filters: Array<string>) {
    // 検索対象としたいシナリオ一覧を設定する。
    this.condition.scenarioName = [];
    for (const filter of filters) {
      this.condition.scenarioName.push(filter);
    }
  }

  /** 対象シナリオカテゴリーを検索条件に設定 */
  setScenarioCategorySelectCondition(filters: Array<string>) {
    // 検索対象としたいシナリオ一覧を設定する。
    this.condition.scenarioCategory = [];
    for (const filter of filters) {
      this.condition.scenarioCategory.push(filter);
    }
  }

  /** 対象シナリオサイトを検索条件に設定 */
  setScenarioSiteSelectCondition(filters: Array<string>) {
    // 検索対象としたいシナリオ一覧を設定する。
    this.condition.scenarioSite = [];
    for (const filter of filters) {
      this.condition.scenarioSite.push(filter);
    }
  }

  /** ページ種類リストフィルタを取得 */
  getAllPageTypeList(): any {
    return this.allPageTypeList;
  }

  /** シナリオ名フィルタリストを取得 */
  getAllScenarioNameList(): any {
    return this.allScenarioNameList;
  }

  /** シナリオカテゴリーフィルタリストを取得 */
  getAllScenarioCategoryList(): any {
    return this.allScenarioCategoryList;
  }

  /** シナリオサイトフィルタリストを取得 */
  getAllScenarioSiteList(): any {
    return this.allScenarioSiteList;
  }

  /** 対象ページ種類を検索条件に設定 */
  setPageTypeSelectCondition(filters: Array<string>) {
    this.condition.pageTypeList = [];
    for (const filter of filters) {
      this.condition.pageTypeList.push(filter);
    }
  }

  /** 対象端末種類を検索条件に設定 */
  setDeviceSelectCondition(filters: Array<string>) {
    this.condition.device = null;
    if (filters.length > 0) {
      if (filters[0] === 'mb' || filters[0] === 'pc') {
        this.condition.device = (filters[0] === 'mb') ? 'mobile' : 'desktop';
      }
    }
  }

  /** 選択中の端末がPCであるかどうかの判定 */
  isPC(): boolean {
    return this.condition.device === 'desktop';
  }

  /** 選択中の端末が携帯であるかどうかの判定 */
  isMobile(): boolean {
    return this.condition.device === 'mobile';
  }

  /**
   * 選択肢として表示する対象を切り替える
   * カテゴリ、ページ種類又はEUMとFMP/PSIを切り替える機能
   * @param targetList 更新後サイトリスト
   */
  switchSiteSelectionList(targetList: any): any {
    this.allSiteList = targetList;
    for (const site of targetList) {
      this.allSiteListMap[site.url] = site;
      this.allSiteNameMap[site.name] = site.url;
    }
  }

  /**
   * カテゴリリストフィルタを作成する
   * @param targetList 更新後カテゴリリスト
   */
  createCategorySelectionList(targetList: any): any {
    this.allCategoryList = targetList;
  }

  /**
   * ページ種類リストフィルタを作成する
   * @param targetList 更新後ページ種類リスト
   */
  createPageTypeSelectionList(targetList: any): any {
    this.allPageTypeList = targetList;
  }

  /**
   * シナリオ名リストフィルタを作成する
   * @param targetList 更新後ページ種類リスト
   */
  createScenarioNameSelectionList(targetList: any): any {
    this.allScenarioNameList = targetList;
  }

  /**
   * シナリオカテゴリーリストフィルタを作成する
   * @param targetList 更新後ページ種類リスト
   */
  createScenarioCategorySelectionList(targetList: any): any {
    this.allScenarioCategoryList = targetList;
  }

  /**
   * シナリオサイトリストフィルタを作成する
   * @param targetList 更新後ページ種類リスト
   */
  createScenarioSiteSelectionList(targetList: any): any {
    this.allScenarioSiteList = targetList;
  }

  /**
   * 検索条件の対象サイトリストをクリア
   */
  clearSiteList() {
    this.condition.siteList = [];
  }

  /**
   * 検索条件の対象カテゴリリストをクリア
   */
  clearCategoryList() {
    this.condition.categoryList = [];
  }

  /**
   * 検索条件の対象ページ種類リストをクリア
   */
  clearPageTypeList() {
    this.condition.pageTypeList = [];
  }

  /**
   * 検索条件の対象シナリオ名リストをクリア
   */
  clearScenarioNameList() {
    this.condition.scenarioName = [];
  }

  /**
   * 検索条件の対象シナリオカテゴリーリストをクリア
   */
  clearScenarioCategoryList() {
    this.condition.scenarioCategory = [];
  }

  /**
   * 検索条件の対象シナリオサイトリストをクリア
   */
  clearScenarioSiteList() {
    this.condition.scenarioSite = [];
  }
}
