import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProxyService } from './proxy.service';

/**
 * レポートサーバへリクエストのサービス
 */
@Injectable()
export class ScenarioConfigService {
  /** HttpリクエストのProxy設定 */
  protected proxySetting: any;

  constructor(private _http: HttpClient, protected proxyService: ProxyService) {
    this.proxySetting = this.proxyService.getProxySetting();
    this.proxySetting.headers = this.proxySetting.headers.append(
      'enctype',
      'multipart/form-data',
    );
    this.proxySetting.headers = this.proxySetting.headers.append(
      'X-Requested-With',
      'XMLHttpRequest',
    );
  }

  /**
   * ログインしているユーザーが所属するグループを取得する
   */
  getUserGroups(): any {
    const data = {};

    return this._http.post(
      this.proxySetting.server + 'getUserGroups',
      data,
      this.proxySetting.baseRequestOptions.clone({
        headers: this.proxySetting.headers,
      }),
    );
  }

  /**
   * ログインしているユーザーが所属するグループを取得する
   */
  getMeasurementStandard(scData: number[]): any {
    const data = {
      scenarioConfigIds : scData,
    };

    return this._http.post(
      this.proxySetting.server + 'getMeasurementStandard',
      data,
      this.proxySetting.baseRequestOptions.clone({
        headers: this.proxySetting.headers,
      }),
    );
  }

  /**
   * ログインしているユーザーが参照できるシナリオのサイト名一覧を取得する
   */
  getScenarioSiteNames(): any {
    const data = {};

    return this._http.post(
      this.proxySetting.server + 'getScenarioSiteNames',
      data,
      this.proxySetting.baseRequestOptions.clone({
        headers: this.proxySetting.headers,
      }),
    );
  }

  /**
   * ログインしているユーザーが参照できるシナリオのカテゴリー一覧を取得する
   */
  // getScenarioCategories(sites: []): any {
  getScenarioCategories(categories: []): any {
    const data = {
      // sites: sites,
      category: categories,
    };

    return this._http.post(
      this.proxySetting.server + 'getScenarioCategories',
      data,
      this.proxySetting.baseRequestOptions.clone({
        headers: this.proxySetting.headers,
      }),
    );
  }

  // get scenario names list
  getScenarioNames(sites: []): any {
    const data = {
      sites: sites,
    };

    return this._http.post(
      this.proxySetting.server + 'getScenarioNames',
      data,
      this.proxySetting.baseRequestOptions.clone({
        headers: this.proxySetting.headers,
      }),
    );
  }
  /*
   * 各項目のフィルタで抽出後に、絞り込まれたフィルタアイテムを返す
   */
  getNarrowedDownFilterItems(selectedSiteNames: [], selectedCategories: [], selectedScenarios: []) {
    const selectedItems = {
      siteNames: selectedSiteNames,
      categories: selectedCategories,
      scenarios: selectedScenarios,
    };

    return this._http.post(
      this.proxySetting.server + 'getNarrowedDownFilterItems',
      selectedItems,
      this.proxySetting.baseRequestOptions.clone({
        headers: this.proxySetting.headers,
      }),
    );

  }

  /**
   * シナリオ操作画面のシナリオ一覧を取得する
   * @param scenarioName 検索エリアで入力したシナリオ名
   * @param groupId 検索エリアで入力したグループ
   * @param siteName 検索エリアで入力したサイト名
   * @param category 検索エリアで入力したカテゴリー
   * @param run_scenario 検索エリアでシナリオ実行

   */
  getScenarioConfigs(
    scenarioName: string,
    groupId: number,
    siteName: string,
    category: string,
    run_scenario: number,
  ): any {
    const data = {
      scenarioName: scenarioName,
      groupId: groupId,
      siteName: siteName,
      category: category,
      run_scenario: run_scenario,
    };
    return this._http.post(
      this.proxySetting.server + 'getScenarioConfigs',
      data,
      this.proxySetting.baseRequestOptions.clone({
        headers: this.proxySetting.headers,
      }),
    );
  }

  /**
   * シナリオ登録（アップロード）
   * @param data 送信するフォームデータ
   */
  addScenarioConfigs(data: FormData): any {
    return this._http.post(
      this.proxySetting.server + 'addScenarioConfig',
      data,
      this.proxySetting.baseRequestOptions.clone({
        headers: this.proxySetting.headers,
      }),
    );
  }

  /**
   * シナリオ編集画面に表示するシナリオ情報を取得する
   * @param scenarioId 編集するシナリオのID
   */
  getScenarioConfig(scenarioId: number): any {
    const data = {
      scenarioId: scenarioId,
    };

    return this._http.post(
      this.proxySetting.server + 'getScenarioConfig',
      data,
      this.proxySetting.baseRequestOptions.clone({
        headers: this.proxySetting.headers,
      }),
    );
  }

  /**
   * get group data by scenario config id
   */
  getGroupByScenario(scenarioId: number): any {
    const data = {
      scenarioId: scenarioId,
    };

    return this._http.post(
      this.proxySetting.server + 'getGroupByScenario',
      data,
      this.proxySetting.baseRequestOptions.clone({
        headers: this.proxySetting.headers,
      }),
    );
  }

  /**
   * シナリオ更新
   * @param data 送信するフォームデータ
   */
  editScenarioConfigs(data: FormData): any {
    return this._http.post(
      this.proxySetting.server + 'editScenarioConfig',
      data,
      this.proxySetting.baseRequestOptions.clone({
        headers: this.proxySetting.headers,
      }),
    );
  }

  /**
   * 実行結果確認画面に表示するシナリオ実行結果情報を取得する
   * @param data 送信するフォームデータ
   */
  getScenarioRunResult(data: FormData): any {
    return this._http.post(
      this.proxySetting.server + 'getScenarioRunResult',
      data,
      this.proxySetting.baseRequestOptions.clone({
        headers: this.proxySetting.headers,
      }),
    );
  }

  /**
   * シナリオの作製後つぐにキューを作製する機能
   * @param scenarioId
   * @returns
   */
  createInstantQueue(scenarioId: number): any {
    const data = {
      scenarioId: scenarioId,
    };

    return this._http.post(
      this.proxySetting.server + 'createInstantQueue',
      data,
      this.proxySetting.baseRequestOptions.clone({
        headers: this.proxySetting.headers,
      }),
    );
  }

  /**
   * 指定したシナリオ情報と実行結果を削除する
   * @param scenarioId
   */
  deleteScenario(scenarioId: number): any {
    const data = {
      scenarioId: scenarioId,
    };

    return this._http.post(
      this.proxySetting.server + 'deleteScenario',
      data,
      this.proxySetting.baseRequestOptions.clone({
        headers: this.proxySetting.headers,
      }),
    );
  }
}
