/**
 * @license
 * Copyright Airitech. All Rights Reserved.
 */
import { Component, OnInit, Inject } from '@angular/core';
import { AnalyticsService } from './@core/utils/analytics.service';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ProxyService } from './@core/data/proxy.service';
import { Title } from '@angular/platform-browser';
import { PAGES_INFO } from './app-const';

import { NbIconLibraries } from '@nebular/theme';

declare var ineum: any;
const TITLE_SUFFIX = ' | Dashboard | Radical-Speed';

/**
 * Applicationコンポネント
 */
@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  /** Appのタイトル */
  titleValue: string = null;

  constructor(private analytics: AnalyticsService, private router: Router,
    @Inject(DOCUMENT) private document: any,
    private proxyService: ProxyService,
    private titleService: Title,
    private iconLibraries: NbIconLibraries) {
      this.iconLibraries.registerFontPack('nebular-icons');
      this.iconLibraries.setDefaultPack('nebular-icons');
  }

  /**
   * コンポネント初期処理
   */
  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (PAGES_INFO[event.url]) {
          const pageTitle = PAGES_INFO[event.url].title;
          this.titleService.setTitle(pageTitle + ' | Radical-Speed');
          this.titleValue = pageTitle;
        }
        // monitoring config遷移の場合は、すぐに遷移する。
        if (event.url === '/pages/monitoring-config') {
          this.moveMonitoringConfig();
        }
      }
      // eum monitoring
      if (typeof ineum !== 'undefined') {
        this.executeEumMonitoring(event, this.titleValue);
      }
    });

    this.analytics.trackPageViews();
  }

  /**
   * EUMを監視する
   * @param target イベント
   */
  executeEumMonitoring(event: any, titleValue: any): void {
    let componentName = (!(titleValue == null)) ? titleValue : 'default';
    componentName += TITLE_SUFFIX;
    if (event instanceof NavigationStart) {
      ineum('page', componentName);
      ineum('startSpaPageTransition');
    } else if (event instanceof NavigationEnd) {
      ineum('meta', 'component', componentName);
      ineum('endSpaPageTransition', { status: 'completed' });
    } else if (event instanceof NavigationError) {
      ineum('endSpaPageTransition', {
        status: 'error',
        url: window.location.href,
        explanation: event.error.toString(),
      });
    } else if (event instanceof NavigationCancel) {
      ineum('endSpaPageTransition', {
        status: 'aborted',
        url: window.location.href,
        explanation: event.reason,
      });
    }
  }

  /**
   * 監視サイト設定ページへ移動処理
   */
  moveMonitoringConfig(): void {
    const proxySetting = this.proxyService.getProxySetting();
    this.document.location.assign(proxySetting.server + 'configs');
  }

  /**
   * Appのタイトルを設定
   * @param newTitle 新タイトル
   */
  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
