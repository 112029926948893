import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProxyService } from './proxy.service';

/**
 * レポートサーバへリクエストのサービス
 */
@Injectable()
export class AccountConfigService {
  /** HttpリクエストのProxy設定 */
  protected proxySetting: any;

  constructor(private _http: HttpClient, protected proxyService: ProxyService) {
    this.proxySetting = this.proxyService.getProxySetting();
    this.proxySetting.headers = this.proxySetting.headers.append('enctype', 'multipart/form-data');
    this.proxySetting.headers = this.proxySetting.headers.append('X-Requested-With', 'XMLHttpRequest');
  }

  /**
   * アカウント情報を取得する
   */
  getAccountConfig(): any {
    const data = {};

    return this._http.post(this.proxySetting.server + 'getAccountConfig', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * パスワード更新
   * @param data 送信するフォームデータ
   */
  editPasswordConfigs(selectedImageUrl): any {
    return this._http.post(this.proxySetting.server + 'editAccountPassword', selectedImageUrl,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

   /**
   * ァィコン更新
   * @param data 送信するフォームデータ
   */
   editIconConfigs(data: FormData): any {
    return this._http.post(this.proxySetting.server + 'editAccountIcon', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  /**
   * 所属グループ情報を取得する
   */
  getGroupInfo(): any {
    const data = {};
    return this._http.post(this.proxySetting.server + 'getGroupInfo', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }
}
