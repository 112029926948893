import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProxyService } from './proxy.service';




/**
 * 監視対象設定コンポネントのサービス
 */
@Injectable()
export class ConfigService {
  /** Httpリクエストのproxy設定 */
  protected proxySetting: any;

  constructor(private _http: HttpClient, protected proxyService: ProxyService) {
    this.proxySetting = this.proxyService.getProxySetting();
    this.proxySetting.headers = this.proxySetting.headers.append('enctype', 'multipart/form-data');
    this.proxySetting.headers = this.proxySetting.headers.append('X-Requested-With', 'XMLHttpRequest');
  }

  /**
   * 監視対象設定一覧を取得
   */
  getConfig() {
    return this._http.get(this.proxySetting.server + 'getMonitoringConfig');
  }

  /**
   * シナリオ一覧を取得
   */
  getScenarioPage() {
    return this._http.get(this.proxySetting.server + 'getScenarioPage');
  }

  getScenarioName(): any {
    const data = {};

    return this._http.post(this.proxySetting.server + 'getScenarioName', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  getScenarioCategory(): any {
    const data = {};

    return this._http.post(this.proxySetting.server + 'getScenarioCategory', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }

  getScenarioSite(): any {
    const data = {};

    return this._http.post(this.proxySetting.server + 'getScenarioSite', data,
    this.proxySetting.baseRequestOptions.clone({headers: this.proxySetting.headers}));
  }
}
