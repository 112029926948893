import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * スクリーンキャプチャのフィルタサービス
 */
@Injectable()
export class ScreenCaptureFilterService {
    /** 対象サイトリスト */
    private allTargetSitesList: any = {};
    /** ソート後対象サイトリスト */
    private sortedTargetSiteList: any = {};
    /** 対象サイトリスト状態Observer */
    protected targetSitesState: Subject<any> = new Subject();
    /** 表示用対象サイトリスト状態Observer */
    protected displayTargetSitesState: Subject<any> = new Subject();

    constructor() {
    }

    /**
     * 対象サイトリストの状態を設定
     * @param targetSites 対象サイトリスト
     */
    setTargetSitesState(targetSites: any) {
        this.allTargetSitesList = targetSites;
        this.targetSitesState.next();
    }

    /**
     * 対象サイトリストの状態Observerを取得
     */
    getTargetSitesState(): Subject<any> {
        return this.targetSitesState;
    }

    /**
     * 表示用対象サイトリストの状態を設定
     * @param displayTargetSites 表示用対象サイトリスト
     */
    setDisplayTargetSitesState(displayTargetSites: any) {
        this.displayTargetSitesState.next(displayTargetSites);
    }

    /**
     * 表示用対象サイトリスト状態Observerを取得
     */
    getDisplayTargetSitesState(): Subject<any> {
        return this.displayTargetSitesState;
    }

    /**
     * ソート後の対象サイトリストを設定
     * @param targetSites ソート後対象サイトリスト
     */
    setSortedTargetSitesList(targetSites: any) {
        this.sortedTargetSiteList = targetSites;
    }

    /**
     * ソート後の対象サイトリストを取得
     */
    getSortedTargetSiteList(): any {
        return this.sortedTargetSiteList;
    }

    /**
     * 対象サイトリストを取得
     */
    getAllTargetSitesList(): any {
        return this.allTargetSitesList;
    }
}
